import { useCalendarEventMaintenanceVM } from "presentation/hook/CalendarEvent/useCalendarEventMaintenanceVM";
import { useCalendarEventMaintenanceTracked } from "presentation/store/CalendarEvent/CalendarEventMaintenanceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import CalendarEventTablePanel from "./CalendarEventTablePanel";
const CalendarEventMaintenance:React.FC = () => { 
    const [isLoading, setIsLoading] = useState(false); 
    const [calendarEventState] = useCalendarEventMaintenanceTracked(); 
    const calendarEventVM = useCalendarEventMaintenanceVM(); 
    const {isBackMaster} = calendarEventState; 

    useEffect(() => { 
        const initialScreen = async() => {    
            setIsLoading(true); 
            try {      
                const results = await Promise.allSettled([ 
                    calendarEventVM.onSearch(),
                    calendarEventVM.loadDropdownOption(), 
                ]); 
                results?.forEach((result, index) => { 
                    if (index === 0 && result.status === 'fulfilled') { 
 
                    }  
                }) 
            } catch(error) { 
                setIsLoading(false); 
            } 
        } 
        initialScreen().then(() => {  
            setIsLoading(false) 
        }).catch(error => {             
            setIsLoading(false) 
        }); 

    }, [isBackMaster,calendarEventVM]) 

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />; 

    return <> 
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}> 
            <SliderPanel 
                isOpen={false} 
                draggable={false} 
                leftSectionWidth={"0%"} 
                rightSectionWidth={"100%"} 
                leftChildren={<CalendarEventTablePanel/>} 
                /> 
        </div> 
    </> 
} 
export default memo(CalendarEventMaintenance); 
